import { pmmHttpClient, pmvHttpClient } from '@/api/instances'
import {
  LIST_USER_EXPORTS, LIST_VALIDATIONS,
  ON_DEMAND_EXPORTS_BOM_VALIDATION,
  GET_USER_EXPORT_METADATA,
  ON_DEMAND_EXPORTS_TEST_CASES_VALIDATION,
  ON_DEMAND_EXPORTS_UNUSABLE_CHOICES_VALIDATION,
  LIST_VALIDATIONS_BASIC,
  LIST_USER_EXPORTS_BY_TYPE
} from '@/api/rest/rule-validation/endpoints'

export const getUserExportsList = async () => pmmHttpClient({
  url: LIST_USER_EXPORTS,
  method: 'GET'
})
export const getUserExportsListByType = async type => pmmHttpClient({
  url: LIST_USER_EXPORTS_BY_TYPE + type,
  method: 'GET'
})

export const createOnDemandExportsBomValidation = async ({
  userExportID = '',
  componentList = [],
  initialPP = '',
  finalPP = ''
} = {}) => pmvHttpClient({
  url: ON_DEMAND_EXPORTS_BOM_VALIDATION.replace(':userExportID', userExportID),
  method: 'POST',
  data: {
    componentList,
    initialPP,
    finalPP
  }
})

export const createOnDemandExportsTestCasesValidation = async ({
  userExportID = '',
  testCaseId = '',
  initialPP = '',
  finalPP = ''
} = {}) => pmvHttpClient({
  url: ON_DEMAND_EXPORTS_TEST_CASES_VALIDATION.replace(':testCaseId', testCaseId),
  method: 'POST',
  data: {
    productModelId: userExportID,
    planningPeriodRange: {
      from: `PP${ initialPP }`,
      to: `PP${ finalPP }`
    }
  }
})

export const createOnDemandExportsUnusableChoiceValidation = async ({
  userExportID = '',
  initialPP = '',
  finalPP = ''
} = {}) => pmvHttpClient({
  url: ON_DEMAND_EXPORTS_UNUSABLE_CHOICES_VALIDATION.replace(':productModelID', userExportID),
  method: 'POST',
  data: {
    initialPP: `PP${ initialPP }`,
    finalPP: `PP${ finalPP }`
  }
})

export const getUserExportMetadata = async id => pmmHttpClient({
  url: GET_USER_EXPORT_METADATA.replace(':userExportID', id),
  method: 'GET'
})

export const getValidations = async id => pmvHttpClient({
  url: LIST_VALIDATIONS.replace(':id', id),
  method: 'GET'
})

export const getTestCasesValidations = async id => pmvHttpClient({
  url: LIST_VALIDATIONS_BASIC.replace(':id', id),
  method: 'GET'
})
